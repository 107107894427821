import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/api/admin/lottery`;

export default {
  show(id) {
    return Client.post(resource, {
      lotteryId: id,
    }, {
      headers: {
        action: "get-lottery-payout",
      },
    });
  },
  update(id, payload) {
    return Client.post(resource, {
      ...payload,
      lotteryId: id,
    }, {
      headers: {
        action: "store-lottery-payout",
      },
    });
  },
};
