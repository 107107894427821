export default {
  baseUrl: process.env.VUE_APP_API_URL,
  authEndpoint: '/v1/api/admin/auth',
  authorizationKey: '1234567890',
  version: process.env.VUE_APP_WEBVERSION,

  // Actions
  loginAction: 'admin-login',
  refreshAction: 'admin-refresh-token',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: '',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
}
