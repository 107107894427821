import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/api/admin/report`;

export default {
  index(payload) {
    payload.startDate = payload.startDate ? `${payload.startDate} 00:00:00` : '';
    payload.endDate = payload.endDate ? `${payload.endDate} 23:59:59` : '';
    let params = prepareSearchParams(payload);
    params.userId = payload.userId;
    params.lotteryId = payload.lotteryId;
    params.post = payload.post;
    return Client.post(`${resource}`, params, {
      headers: {
        action: "list-bet-history",
      },
    });
  },
  total(payload) {
    payload.startDate = payload.startDate ? `${payload.startDate} 00:00:00` : '';
    payload.endDate = payload.endDate ? `${payload.endDate} 23:59:59` : '';
    let params = prepareSearchParams(payload);
    params.userId = payload.userId;
    params.lotteryId = payload.lotteryId;
    params.post = payload.post;
    return Client.post(`${resource}`, params, {
      headers: {
        action: "list-bet-history-total",
      },
    });
  },
};
