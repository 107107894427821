import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/api/admin/lottery`;

export default {
  show(params) {
    return Client.post(resource, params, {
      headers: {
        action: "get-lottery-live",
      },
    });
  },
  save(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "update-lottery-live",
      },
    });
  },
};
