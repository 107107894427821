export default [
  {
    path: '/result',
    name: 'result',
    component: () => import('@/views/result/Index.vue'),
    meta: {
      pageTitle: 'breadcrumb.result',
      resource: 'result',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.result',
          active: true,
        },
      ],
    },
  },
  {
    path: '/live-setup',
    name: 'live-setup',
    component: () => import('@/views/result/resultHistory/Index.vue'),
    meta: {
      pageTitle: 'breadcrumb.result',
      navActiveLink: 'result',
      navActiveTab: 'live-setup',
      resource: 'live-setup',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.result',
          active: true,
        },
      ],
    },
  },
  {
    path: '/define-result',
    name: 'define-result',
    component: () => import('@/views/result/defineResult/Index.vue'),
    meta: {
      pageTitle: 'breadcrumb.result',
      navActiveLink: 'result',
      navActiveTab: 'define-result',
      resource: 'define-result',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.result',
          active: true,
        },
      ],
    },
  },
  // {
  //   path: '/live-bet',
  //   name: 'live-bet',
  //   component: () => import('@/views/result/liveBet/Index.vue'),
  //   meta: {
  //     pageTitle: 'breadcrumb.result',
  //     navActiveLink: 'result',
  //     navActiveTab: 'live-bet',
  //     resource: 'live-bet',
  //     action: 'read',
  //     breadcrumb: [
  //       {
  //         text: 'breadcrumb.result',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
]
