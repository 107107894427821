export default [
  {
    path: '/senior',
    name: 'list-senior',
    component: () => import('@/views/senior/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.senior',
      resource: 'senior',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSenior',
          active: true,
        },
      ],
    },
  },
  {
    path: '/senior/create',
    name: 'create-senior',
    component: () => import('@/views/senior/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.senior',
      navActiveLink: 'list-senior',
      resource: 'senior',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listSenior',
          to: { name: 'list-senior' }
        },
        {
          text: 'breadcrumb.createSenior',
          active: true,
        },
      ],
    },
  },
  {
    path: '/senior/:id/update',
    name: 'update-senior',
    component: () => import('@/views/senior/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.senior',
      navActiveLink: 'list-senior',
      navActiveTab: 'update-senior',
      resource: 'senior',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSenior',
          to: { name: 'list-senior' }
        },
        {
          text: 'breadcrumb.updateSenior',
          active: true,
        },
      ],
    },
  },
  {
    path: '/senior/:id',
    name: 'view-senior',
    component: () => import('@/views/senior/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.senior',
      navActiveLink: 'list-senior',
      resource: 'senior',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSenior',
          to: { name: 'list-senior' }
        },
        {
          text: 'breadcrumb.viewSenior',
          active: true,
        },
      ],
    },
  },
  // Block Number
  {
    path: '/senior/:id/block-number',
    name: 'list-senior-block-number',
    component: () => import('@/views/senior/blockNumber/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.blockNumber',
      navActiveLink: 'list-senior',
      navActiveTab: 'senior-block-number',
      resource: 'senior',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSenior',
          to: { name: 'list-senior' }
        },
        {
          text: 'breadcrumb.listBlockNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/senior/:id/block-number/create',
    name: 'create-senior-block-number',
    component: () => import('@/views/senior/blockNumber/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.blockNumber',
      navActiveLink: 'list-senior',
      navActiveTab: 'senior-block-number',
      resource: 'senior',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSenior',
          to: { name: 'list-senior' }
        },
        {
          text: 'breadcrumb.listBlockNumber',
          to: { name: 'list-senior-block-number' }
        },
        {
          text: 'breadcrumb.createBlockNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/senior/:id/block-number/:blockId/update',
    name: 'update-senior-block-number',
    component: () => import('@/views/senior/blockNumber/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.blockNumber',
      navActiveLink: 'list-senior',
      navActiveTab: 'senior-block-number',
      resource: 'senior',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSenior',
          to: { name: 'list-senior' }
        },
        {
          text: 'breadcrumb.listBlockNumber',
          to: { name: 'list-senior-block-number' }
        },
        {
          text: 'breadcrumb.updateBlockNumber',
          active: true,
        },
      ],
    },
  },
  // Allow Number
  {
    path: '/senior/:id/allow-number',
    name: 'list-senior-allow-number',
    component: () => import('@/views/senior/allowNumber/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.allowNumber',
      navActiveLink: 'list-senior',
      navActiveTab: 'senior-allow-number',
      resource: 'senior',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSenior',
          to: { name: 'list-senior' }
        },
        {
          text: 'breadcrumb.listAllowNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/senior/:id/allow-number/create',
    name: 'create-senior-allow-number',
    component: () => import('@/views/senior/allowNumber/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.allowNumber',
      navActiveLink: 'list-senior',
      navActiveTab: 'senior-allow-number',
      resource: 'senior',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSenior',
          to: { name: 'list-senior' }
        },
        {
          text: 'breadcrumb.listAllowNumber',
          to: { name: 'list-senior-allow-number' }
        },
        {
          text: 'breadcrumb.createAllowNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/senior/:id/allow-number/update',
    name: 'update-senior-allow-number',
    component: () => import('@/views/senior/allowNumber/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.allowNumber',
      navActiveLink: 'list-senior',
      navActiveTab: 'senior-allow-number',
      resource: 'senior',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSenior',
          to: { name: 'list-senior' }
        },
        {
          text: 'breadcrumb.listAllowNumber',
          to: { name: 'list-senior-allow-number' }
        },
        {
          text: 'breadcrumb.updateAllowNumber',
          active: true,
        },
      ],
    },
  },
  // Sell out
  {
    path: '/senior/:id/sell-out',
    name: 'list-senior-sell-out',
    component: () => import('@/views/senior/sellOut/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.sellOut',
      navActiveLink: 'list-senior',
      navActiveTab: 'senior-sell-out',
      resource: 'senior',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSellOut',
          active: true,
        },
      ],
    },
  },
  {
    path: '/senior/:id/sell-out/create',
    name: 'create-senior-sell-out',
    component: () => import('@/views/senior/sellOut/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.sellOut',
      navActiveLink: 'list-senior',
      navActiveTab: 'senior-sell-out',
      resource: 'senior',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSellOut',
          to: { name: 'list-senior-sell-out' }
        },
        {
          text: 'breadcrumb.createSellOut',
          active: true,
        },
      ],
    },
  },
  {
    path: '/senior/:id/sell-out/:sellOutId/update',
    name: 'update-senior-sell-out',
    component: () => import('@/views/senior/sellOut/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.sellOut',
      navActiveLink: 'list-senior',
      navActiveTab: 'senior-sell-out',
      resource: 'senior',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSellOut',
          to: { name: 'list-senior-sell-out' }
        },
        {
          text: 'breadcrumb.updateSellOut',
          active: true,
        },
      ],
    },
  },
  // Setting
  {
    path: '/senior/:id/bet-setting',
    name: 'list-senior-bet-setting',
    component: () => import('@/views/senior/betSetting/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.betSetting',
      navActiveLink: 'list-senior',
      navActiveTab: 'senior-bet-setting',
      resource: 'senior',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listBetSetting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/senior/:id/bet-setting/create',
    name: 'create-senior-bet-setting',
    component: () => import('@/views/senior/betSetting/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.betSetting',
      navActiveLink: 'list-senior',
      navActiveTab: 'senior-bet-setting',
      resource: 'senior',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listBetSetting',
          to: { name: 'list-senior-bet-setting' }
        },
        {
          text: 'breadcrumb.createBetSetting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/senior/:id/bet-setting/:betSettingId/update',
    name: 'update-senior-bet-setting',
    component: () => import('@/views/senior/betSetting/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.betSetting',
      navActiveLink: 'list-senior',
      navActiveTab: 'senior-bet-setting',
      resource: 'senior',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listBetSetting',
          to: { name: 'list-senior-bet-setting' }
        },
        {
          text: 'breadcrumb.updateBetSetting',
          active: true,
        },
      ],
    },
  },
]
