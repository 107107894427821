export default [
  {
    path: '/player',
    name: 'list-player',
    component: () => import('@/views/player/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.player',
      resource: 'player',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPlayer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/player/create',
    name: 'create-player',
    component: () => import('@/views/player/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.player',
      navActiveLink: 'list-player',
      resource: 'player',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listPlayer',
          to: { name: 'list-player' }
        },
        {
          text: 'breadcrumb.createPlayer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/player/:id/update',
    name: 'update-player',
    component: () => import('@/views/player/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.player',
      navActiveLink: 'list-player',
      navActiveTab: 'update-player',
      resource: 'player',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPlayer',
          to: { name: 'list-player' }
        },
        {
          text: 'breadcrumb.updatePlayer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/player/:id',
    name: 'view-player',
    component: () => import('@/views/player/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.player',
      navActiveLink: 'list-player',
      resource: 'player',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPlayer',
          to: { name: 'list-player' }
        },
        {
          text: 'breadcrumb.viewPlayer',
          active: true,
        },
      ],
    },
  },
  // Block Number
  {
    path: '/player/:id/block-number',
    name: 'list-player-block-number',
    component: () => import('@/views/player/blockNumber/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.blockNumber',
      navActiveLink: 'list-player',
      navActiveTab: 'player-block-number',
      resource: 'player',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPlayer',
          to: { name: 'list-player' }
        },
        {
          text: 'breadcrumb.listBlockNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/player/:id/block-number/create',
    name: 'create-player-block-number',
    component: () => import('@/views/player/blockNumber/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.blockNumber',
      navActiveLink: 'list-player',
      navActiveTab: 'player-block-number',
      resource: 'player',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPlayer',
          to: { name: 'list-player' }
        },
        {
          text: 'breadcrumb.listBlockNumber',
          to: { name: 'list-player-block-number' }
        },
        {
          text: 'breadcrumb.createBlockNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/player/:id/block-number/:blockId/update',
    name: 'update-player-block-number',
    component: () => import('@/views/player/blockNumber/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.blockNumber',
      navActiveLink: 'list-player',
      navActiveTab: 'player-block-number',
      resource: 'player',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPlayer',
          to: { name: 'list-player' }
        },
        {
          text: 'breadcrumb.listBlockNumber',
          to: { name: 'list-player-block-number' }
        },
        {
          text: 'breadcrumb.updateBlockNumber',
          active: true,
        },
      ],
    },
  },
  // Allow Number
  {
    path: '/player/:id/allow-number',
    name: 'list-player-allow-number',
    component: () => import('@/views/player/allowNumber/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.allowNumber',
      navActiveLink: 'list-player',
      navActiveTab: 'player-allow-number',
      resource: 'player',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listPlayer',
          to: { name: 'list-player' }
        },
        {
          text: 'breadcrumb.listAllowNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/player/:id/allow-number/create',
    name: 'create-player-allow-number',
    component: () => import('@/views/player/allowNumber/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.allowNumber',
      navActiveLink: 'list-player',
      navActiveTab: 'player-allow-number',
      resource: 'player',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPlayer',
          to: { name: 'list-player' }
        },
        {
          text: 'breadcrumb.listAllowNumber',
          to: { name: 'list-player-allow-number' }
        },
        {
          text: 'breadcrumb.createAllowNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/player/:id/allow-number/:allowId/update',
    name: 'update-player-allow-number',
    component: () => import('@/views/player/allowNumber/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.allowNumber',
      navActiveLink: 'list-player',
      navActiveTab: 'player-allow-number',
      resource: 'player',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listPlayer',
          to: { name: 'list-player' }
        },
        {
          text: 'breadcrumb.listAllowNumber',
          to: { name: 'list-player-allow-number' }
        },
        {
          text: 'breadcrumb.updateAllowNumber',
          active: true,
        },
      ],
    },
  },
  // Sell out
  {
    path: '/player/:id/sell-out',
    name: 'list-player-sell-out',
    component: () => import('@/views/player/sellOut/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.sellOut',
      navActiveLink: 'list-player',
      navActiveTab: 'player-sell-out',
      resource: 'player',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSellOut',
          active: true,
        },
      ],
    },
  },
  {
    path: '/player/:id/sell-out/create',
    name: 'create-player-sell-out',
    component: () => import('@/views/player/sellOut/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.sellOut',
      navActiveLink: 'list-player',
      navActiveTab: 'player-sell-out',
      resource: 'player',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSellOut',
          to: { name: 'list-player-sell-out' }
        },
        {
          text: 'breadcrumb.createSellOut',
          active: true,
        },
      ],
    },
  },
  {
    path: '/player/:id/sell-out/:sellOutId/update',
    name: 'update-player-sell-out',
    component: () => import('@/views/player/sellOut/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.sellOut',
      navActiveLink: 'list-player',
      navActiveTab: 'player-sell-out',
      resource: 'player',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSellOut',
          to: { name: 'list-player-sell-out' }
        },
        {
          text: 'breadcrumb.updateSellOut',
          active: true,
        },
      ],
    },
  },
  // Setting
  {
    path: '/player/:id/bet-setting',
    name: 'list-player-bet-setting',
    component: () => import('@/views/player/betSetting/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.betSetting',
      navActiveLink: 'list-player',
      navActiveTab: 'player-bet-setting',
      resource: 'player',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listBetSetting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/player/:id/bet-setting/create',
    name: 'create-player-bet-setting',
    component: () => import('@/views/player/betSetting/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.betSetting',
      navActiveLink: 'list-player',
      navActiveTab: 'player-bet-setting',
      resource: 'player',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listBetSetting',
          to: { name: 'list-player-bet-setting' }
        },
        {
          text: 'breadcrumb.createBetSetting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/player/:id/bet-setting/:betSettingId/update',
    name: 'update-player-bet-setting',
    component: () => import('@/views/player/betSetting/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.betSetting',
      navActiveLink: 'list-player',
      navActiveTab: 'player-bet-setting',
      resource: 'player',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listBetSetting',
          to: { name: 'list-player-bet-setting' }
        },
        {
          text: 'breadcrumb.updateBetSetting',
          active: true,
        },
      ],
    },
  },
]
