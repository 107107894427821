export default [
  {
    path: '/company',
    name: 'list-company',
    component: () => import('@/views/company/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.company',
      resource: 'company',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listCompany',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/create',
    name: 'create-company',
    component: () => import('@/views/company/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.company',
      navActiveLink: 'list-company',
      resource: 'company',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listCompany',
          to: { name: 'list-company' }
        },
        {
          text: 'breadcrumb.createCompany',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/:id/update',
    name: 'update-company',
    component: () => import('@/views/company/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.company',
      navActiveLink: 'list-company',
      navActiveTab: 'update-company',
      resource: 'company',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listCompany',
          to: { name: 'list-company' }
        },
        {
          text: 'breadcrumb.updateCompany',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/:id',
    name: 'view-company',
    component: () => import('@/views/company/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.company',
      navActiveLink: 'list-company',
      resource: 'company',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listCompany',
          to: { name: 'list-company' }
        },
        {
          text: 'breadcrumb.viewCompany',
          active: true,
        },
      ],
    },
  },
  // Block Number
  {
    path: '/company/:id/block-number',
    name: 'list-company-block-number',
    component: () => import('@/views/company/blockNumber/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.blockNumber',
      navActiveLink: 'list-company',
      navActiveTab: 'company-block-number',
      resource: 'company',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listCompany',
          to: { name: 'list-company' }
        },
        {
          text: 'breadcrumb.listBlockNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/:id/block-number/create',
    name: 'create-company-block-number',
    component: () => import('@/views/company/blockNumber/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.blockNumber',
      navActiveLink: 'list-company',
      navActiveTab: 'company-block-number',
      resource: 'company',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listCompany',
          to: { name: 'list-company' }
        },
        {
          text: 'breadcrumb.listBlockNumber',
          to: { name: 'list-company-block-number' }
        },
        {
          text: 'breadcrumb.createBlockNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/:id/block-number/:blockId/update',
    name: 'update-company-block-number',
    component: () => import('@/views/company/blockNumber/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.blockNumber',
      navActiveLink: 'list-company',
      navActiveTab: 'company-block-number',
      resource: 'company',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listCompany',
          to: { name: 'list-company' }
        },
        {
          text: 'breadcrumb.listBlockNumber',
          to: { name: 'list-company-block-number' }
        },
        {
          text: 'breadcrumb.updateBlockNumber',
          active: true,
        },
      ],
    },
  },
  // Allow Number
  {
    path: '/company/:id/allow-number',
    name: 'list-company-allow-number',
    component: () => import('@/views/company/allowNumber/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.allowNumber',
      navActiveLink: 'list-company',
      navActiveTab: 'company-allow-number',
      resource: 'company',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listCompany',
          to: { name: 'list-company' }
        },
        {
          text: 'breadcrumb.listAllowNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/:id/allow-number/create',
    name: 'create-company-allow-number',
    component: () => import('@/views/company/allowNumber/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.allowNumber',
      navActiveLink: 'list-company',
      navActiveTab: 'company-allow-number',
      resource: 'company',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listCompany',
          to: { name: 'list-company' }
        },
        {
          text: 'breadcrumb.listAllowNumber',
          to: { name: 'list-company-allow-number' }
        },
        {
          text: 'breadcrumb.createAllowNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/:id/allow-number/:allowId/update',
    name: 'update-company-allow-number',
    component: () => import('@/views/company/allowNumber/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.allowNumber',
      navActiveLink: 'list-company',
      navActiveTab: 'company-allow-number',
      resource: 'company',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listCompany',
          to: { name: 'list-company' }
        },
        {
          text: 'breadcrumb.listAllowNumber',
          to: { name: 'list-company-allow-number' }
        },
        {
          text: 'breadcrumb.updateAllowNumber',
          active: true,
        },
      ],
    },
  },
  // Sell out
  {
    path: '/company/:id/sell-out',
    name: 'list-company-sell-out',
    component: () => import('@/views/company/sellOut/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.sellOut',
      navActiveLink: 'list-company',
      navActiveTab: 'company-sell-out',
      resource: 'company',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSellOut',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/:id/sell-out/create',
    name: 'create-company-sell-out',
    component: () => import('@/views/company/sellOut/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.sellOut',
      navActiveLink: 'list-company',
      navActiveTab: 'company-sell-out',
      resource: 'company',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSellOut',
          to: { name: 'list-company-sell-out' }
        },
        {
          text: 'breadcrumb.createSellOut',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/:id/sell-out/:sellOutId/update',
    name: 'update-company-sell-out',
    component: () => import('@/views/company/sellOut/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.sellOut',
      navActiveLink: 'list-company',
      navActiveTab: 'company-sell-out',
      resource: 'company',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSellOut',
          to: { name: 'list-company-sell-out' }
        },
        {
          text: 'breadcrumb.updateSellOut',
          active: true,
        },
      ],
    },
  },
  // Setting
  {
    path: '/company/:id/bet-setting',
    name: 'list-company-bet-setting',
    component: () => import('@/views/company/betSetting/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.betSetting',
      navActiveLink: 'list-company',
      navActiveTab: 'company-bet-setting',
      resource: 'company',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listBetSetting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/:id/bet-setting/create',
    name: 'create-company-bet-setting',
    component: () => import('@/views/company/betSetting/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.betSetting',
      navActiveLink: 'list-company',
      navActiveTab: 'company-bet-setting',
      resource: 'company',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listBetSetting',
          to: { name: 'list-company-bet-setting' }
        },
        {
          text: 'breadcrumb.createBetSetting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/company/:id/bet-setting/:betSettingId/update',
    name: 'update-company-bet-setting',
    component: () => import('@/views/company/betSetting/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.betSetting',
      navActiveLink: 'list-company',
      navActiveTab: 'company-bet-setting',
      resource: 'company',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listBetSetting',
          to: { name: 'list-company-bet-setting' }
        },
        {
          text: 'breadcrumb.updateBetSetting',
          active: true,
        },
      ],
    },
  },
]
