import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import store from '@/store';

const resource = `${Config.basePath}/v1/api/admin/report`;

export default {
  index(payload) {
    payload.limit = store.state.pagination.perPage;

    return Client.post(`${resource}`, payload, {
      headers: {
        action: "list-bet-number-v2",
      },
    });
  },
  total(payload) {
    return Client.post(`${resource}`, payload, {
      headers: {
        action: "list-bet-number-v2-total",
      },
    });
  },
};
