export default [
  {
    path: '/agent',
    name: 'list-agent',
    component: () => import('@/views/agent/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.agent',
      resource: 'agent',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listAgent',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/create',
    name: 'create-agent',
    component: () => import('@/views/agent/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.agent',
      navActiveLink: 'list-agent',
      resource: 'agent',
      action: 'create',
      breadcrumb: [
        {
          text: 'breadcrumb.listAgent',
          to: { name: 'list-agent' }
        },
        {
          text: 'breadcrumb.createAgent',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/:id/update',
    name: 'update-agent',
    component: () => import('@/views/agent/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.agent',
      navActiveLink: 'list-agent',
      navActiveTab: 'update-agent',
      resource: 'agent',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listAgent',
          to: { name: 'list-agent' }
        },
        {
          text: 'breadcrumb.updateAgent',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/:id',
    name: 'view-agent',
    component: () => import('@/views/agent/View.vue'),
    meta: {
      pageTitle: 'breadcrumb.agent',
      navActiveLink: 'list-agent',
      resource: 'agent',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listAgent',
          to: { name: 'list-agent' }
        },
        {
          text: 'breadcrumb.viewAgent',
          active: true,
        },
      ],
    },
  },
  // Block Number
  {
    path: '/agent/:id/block-number',
    name: 'list-agent-block-number',
    component: () => import('@/views/agent/blockNumber/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.blockNumber',
      navActiveLink: 'list-agent',
      navActiveTab: 'agent-block-number',
      resource: 'agent',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listAgent',
          to: { name: 'list-agent' }
        },
        {
          text: 'breadcrumb.listBlockNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/:id/block-number/create',
    name: 'create-agent-block-number',
    component: () => import('@/views/agent/blockNumber/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.blockNumber',
      navActiveLink: 'list-agent',
      navActiveTab: 'agent-block-number',
      resource: 'agent',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listAgent',
          to: { name: 'list-agent' }
        },
        {
          text: 'breadcrumb.listBlockNumber',
          to: { name: 'list-agent-block-number' }
        },
        {
          text: 'breadcrumb.createBlockNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/:id/block-number/:blockId/update',
    name: 'update-agent-block-number',
    component: () => import('@/views/agent/blockNumber/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.blockNumber',
      navActiveLink: 'list-agent',
      navActiveTab: 'agent-block-number',
      resource: 'agent',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listAgent',
          to: { name: 'list-agent' }
        },
        {
          text: 'breadcrumb.listBlockNumber',
          to: { name: 'list-agent-block-number' }
        },
        {
          text: 'breadcrumb.updateBlockNumber',
          active: true,
        },
      ],
    },
  },
  // Allow Number
  {
    path: '/agent/:id/allow-number',
    name: 'list-agent-allow-number',
    component: () => import('@/views/agent/allowNumber/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.allowNumber',
      navActiveLink: 'list-agent',
      navActiveTab: 'agent-allow-number',
      resource: 'agent',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listAgent',
          to: { name: 'list-agent' }
        },
        {
          text: 'breadcrumb.listAllowNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/:id/allow-number/create',
    name: 'create-agent-allow-number',
    component: () => import('@/views/agent/allowNumber/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.allowNumber',
      navActiveLink: 'list-agent',
      navActiveTab: 'agent-allow-number',
      resource: 'agent',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listAgent',
          to: { name: 'list-agent' }
        },
        {
          text: 'breadcrumb.listAllowNumber',
          to: { name: 'list-agent-allow-number' }
        },
        {
          text: 'breadcrumb.createAllowNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/:id/allow-number/:allowId/update',
    name: 'update-agent-allow-number',
    component: () => import('@/views/agent/allowNumber/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.allowNumber',
      navActiveLink: 'list-agent',
      navActiveTab: 'agent-allow-number',
      resource: 'agent',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listAgent',
          to: { name: 'list-agent' }
        },
        {
          text: 'breadcrumb.listAllowNumber',
          to: { name: 'list-agent-allow-number' }
        },
        {
          text: 'breadcrumb.updateAllowNumber',
          active: true,
        },
      ],
    },
  },
  // Sell out
  {
    path: '/agent/:id/sell-out',
    name: 'list-agent-sell-out',
    component: () => import('@/views/agent/sellOut/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.sellOut',
      navActiveLink: 'list-agent',
      navActiveTab: 'agent-sell-out',
      resource: 'agent',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSellOut',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/:id/sell-out/create',
    name: 'create-agent-sell-out',
    component: () => import('@/views/agent/sellOut/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.sellOut',
      navActiveLink: 'list-agent',
      navActiveTab: 'agent-sell-out',
      resource: 'agent',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSellOut',
          to: { name: 'list-agent-sell-out' }
        },
        {
          text: 'breadcrumb.createSellOut',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/:id/sell-out/:sellOutId/update',
    name: 'update-agent-sell-out',
    component: () => import('@/views/agent/sellOut/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.sellOut',
      navActiveLink: 'list-agent',
      navActiveTab: 'agent-sell-out',
      resource: 'agent',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSellOut',
          to: { name: 'list-agent-sell-out' }
        },
        {
          text: 'breadcrumb.updateSellOut',
          active: true,
        },
      ],
    },
  },
  // Setting
  {
    path: '/agent/:id/bet-setting',
    name: 'list-agent-bet-setting',
    component: () => import('@/views/agent/betSetting/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.betSetting',
      navActiveLink: 'list-agent',
      navActiveTab: 'agent-bet-setting',
      resource: 'agent',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listBetSetting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/:id/bet-setting/create',
    name: 'create-agent-bet-setting',
    component: () => import('@/views/agent/betSetting/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.betSetting',
      navActiveLink: 'list-agent',
      navActiveTab: 'agent-bet-setting',
      resource: 'agent',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listBetSetting',
          to: { name: 'list-agent-bet-setting' }
        },
        {
          text: 'breadcrumb.createBetSetting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agent/:id/bet-setting/:betSettingId/update',
    name: 'update-agent-bet-setting',
    component: () => import('@/views/agent/betSetting/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.betSetting',
      navActiveLink: 'list-agent',
      navActiveTab: 'agent-bet-setting',
      resource: 'agent',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listBetSetting',
          to: { name: 'list-agent-bet-setting' }
        },
        {
          text: 'breadcrumb.updateBetSetting',
          active: true,
        },
      ],
    },
  },
]
