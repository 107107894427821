import ProfileRepository from "./Api/ProfileRepository";
import CurrencyRepository from "./Api/CurrencyRepository";
import UserRepository from "./Api/UserRepository";
import RoleRepository from "./Api/RoleRepository";
import PermissionRepository from "./Api/PermissionRepository";
import ZoneRepository from "./Api/ZoneRepository";
import CompanyRepository from "./Api/CompanyRepository";
import SeniorRepository from "./Api/SeniorRepository";
import MasterRepository from "./Api/MasterRepository";
import AgentRepository from "./Api/AgentRepository";
import PlayerRepository from "./Api/PlayerRepository";
import MemberRepository from "./Api/MemberRepository";
import AccountRepository from "./Api/AccountRepository";
import TransactionRepository from "./Api/TransactionRepository";
import BalanceReportRepository from "./Api/BalanceReportRepository";
import DashboardRepository from "./Api/DashboardRepository";
import BetSettingRepository from "./Api/BetSettingRepository";
import FightSetupRepository from "./Api/FightSetupRepository";
import BetHistoryRepository from "./Api/BetHistoryRepository";
import BetInvoiceHistoryRepository from "./Api/BetInvoiceHistoryRepository";
import BetNumberHistoryRepository from "./Api/BetNumberHistoryRepository";
import BlockNumberRepository from "./Api/BlockNumberRepository";
import MemberBlockNumberRepository from "./Api/MemberBlockNumberRepository";
import AllowNumberRepository from "./Api/AllowNumberRepository";
import MemberAllowNumberRepository from "./Api/MemberAllowNumberRepository";
import LotteryRepository from "./Api/LotteryRepository";
import LotteryLiveRepository from "./Api/LotteryLiveRepository";
import LotteryResultRepository from "./Api/LotteryResultRepository";
import LotteryPayoutRepository from "./Api/LotteryPayoutRepository";
import BetRepository from "./Api/BetRepository";
import SellOutRepository from "./Api/SellOutRepository";
import MemberSellOutRepository from "./Api/MemberSellOutRepository";
import MemberBetSettingRepository from "./Api/MemberBetSettingRepository";

const repositories = {
    'dashboard': DashboardRepository,
    'profile': ProfileRepository,
    'currency': CurrencyRepository,
    'user': UserRepository,
    'role': RoleRepository,
    'permission': PermissionRepository,
    'zone': ZoneRepository,
    'company': CompanyRepository,
    'senior': SeniorRepository,
    'master': MasterRepository,
    'agent': AgentRepository,
    'player': PlayerRepository,
    'member': MemberRepository,
    'account': AccountRepository,
    'transaction': TransactionRepository,
    'balanceReport': BalanceReportRepository,
    'betSetting': BetSettingRepository,
    'fightSetup': FightSetupRepository,
    'betHistory': BetHistoryRepository,
    'betInvoiceHistory': BetInvoiceHistoryRepository,
    'betNumberHistory': BetNumberHistoryRepository,
    'blockNumber': BlockNumberRepository,
    'memberBlockNumber': MemberBlockNumberRepository,
    'allowNumber': AllowNumberRepository,
    'memberAllowNumber': MemberAllowNumberRepository,
    'lottery': LotteryRepository,
    'lotteryLive': LotteryLiveRepository,
    'lotteryResult': LotteryResultRepository,
    'lotteryPayout': LotteryPayoutRepository,
    'bet': BetRepository,
    'sellOut': SellOutRepository,
    'memberSellOut': MemberSellOutRepository,
    'memberBetSetting': MemberBetSettingRepository,
};

export default {
    get: name => repositories[name]
};
