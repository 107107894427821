import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/api/admin/user/setting`;

export default {
  index(payload) {
    payload.searchFields = [
      ...payload.searchFields,
    ];
    let params = prepareSearchParams(payload);
    return Client.post(resource, {
      ...params,
      userId: payload.userId,
    }, {
      headers: {
        action: "list-block-number-bet",
      },
    });
  },
  show(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "get-block-number-bet",
      },
    });
  },
  create(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "store-block-number-bet",
      },
    });
  },
  update(id, payload) {
    return Client.post(resource, {
      id: id,
      ...payload,
    }, {
      headers: {
        action: "update-block-number-bet",
      },
    });
  },
  unblock(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "delete-block-number-live-bet",
      },
    });
  },
  destroy(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "delete-block-number-bet",
      },
    });
  },
};
