export default [
  {
    path: '/live-bet',
    name: 'live-bet',
    component: () => import('@/views/liveBet/Index.vue'),
    meta: {
      pageTitle: 'breadcrumb.result',
      navActiveLink: 'live-bet',
      resource: 'live-bet',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.result',
          active: true,
        },
      ],
    },
  },
]
