export default [
  {
    path: '/sell-out',
    name: 'list-sell-out',
    component: () => import('@/views/betSetting/sellOut/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.sellOut',
      navActiveLink: 'bet-setting',
      navActiveTab: 'sell-out',
      resource: 'bet-setting',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listSellOut',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sell-out/create',
    name: 'create-sell-out',
    component: () => import('@/views/betSetting/sellOut/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.sellOut',
      navActiveLink: 'bet-setting',
      navActiveTab: 'sell-out',
      resource: 'bet-setting',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSellOut',
          to: { name: 'list-sell-out' }
        },
        {
          text: 'breadcrumb.createSellOut',
          active: true,
        },
      ],
    },
  },
  {
    path: '/sell-out/:id/update',
    name: 'update-sell-out',
    component: () => import('@/views/betSetting/sellOut/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.sellOut',
      navActiveLink: 'bet-setting',
      navActiveTab: 'sell-out',
      resource: 'bet-setting',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listSellOut',
          to: { name: 'list-sell-out' }
        },
        {
          text: 'breadcrumb.updateSellOut',
          active: true,
        },
      ],
    },
  },
]
