import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/api/admin/account`;

export default {
  index(payload) {
    payload.startDate = payload.startDate ? `${payload.startDate} 00:00:00` : '';
    payload.endDate = payload.endDate ? `${payload.endDate} 23:59:59` : '';
    let params = prepareSearchParams(payload);
    params = {
      ...payload,
      ...params,
      username: payload.search,
    }
    return Client.post(resource, params, {
      headers: {
        action: "list-transaction",
      },
    });
  },
  total(payload) {
    payload.startDate = payload.startDate ? `${payload.startDate} 00:00:00` : '';
    payload.endDate = payload.endDate ? `${payload.endDate} 23:59:59` : '';
    let params = prepareSearchParams(payload);
    params = {
      ...payload,
      ...params,
      username: payload.search,
    }
    return Client.post(resource, params, {
      headers: {
        action: "total-transaction",
      },
    });
  },
};
