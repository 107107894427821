import Vue from 'vue';
import currency from 'currency.js'

Vue.filter('currency', function (value, currencyCode = null, precision = 2) {
    if (typeof value === 'undefined') {
        return '';
    }

    if (currencyCode === 840) {
        const USD = value => currency(value);
        return USD(value).format();
    } else if (currencyCode === 116) {
        const RIEL = value => currency(value, { precision: precision, symbol: '៛' });
        return RIEL(value).format()
    } else {
        const C = value => currency(value, { precision: 2, symbol: '' });
        return C(value).format()
    }
});

Vue.filter('money', function (value, currencyCode = null) {
    if (typeof value === 'undefined') {
        return '';
    }

    let precision = 0;
    let money = value.toString().split(".");
    if (money.length > 1) {
      precision = money[1].length;
    }

    if (currencyCode === 840) {
        const USD = value => currency(value, { precision: precision });
        return USD(value).format();
    } else if (currencyCode === 116) {
        const RIEL = value => currency(value, { precision: precision, symbol: '៛' });
        return RIEL(value).format()
    } else {
        const C = value => currency(value, { precision: 2, symbol: '' });
        return C(value).format()
    }
});
