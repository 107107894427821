import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/api/admin/member`;
const typeId = 5;

export default {
  index(payload) {
    payload.searchFields = [
      ...payload.searchFields,
      { key: 'typeId', },
      { key: 'parentId', },
    ];
    payload = {
      ...payload,
      typeId: typeId,
    };
    let params = prepareSearchParams(payload);
    if (payload.search) {
      params.filters.push({
        searchby: "username",
        searchoperator: "=",
        search: payload.search.toUpperCase(),
      });
    }
    return Client.post(resource, {
      ...params
    }, {
      headers: {
        action: "member-list",
      },
    });
  },
  create(payload) {
    const params = {
      ...payload,
      typeId: typeId,
    };
    return Client.post(resource, params, {
      headers: {
        action: "member-store",
      },
    });
  },
  show(id, payload = {}) {
    const params = {
      ...payload,
      typeId: typeId,
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "member-show",
      },
    });
  },
  update(id, payload) {
    const params = {
      ...payload,
      typeId: typeId,
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "member-update",
      },
    });
  },
  getSuggestUsername(parentId = null, userId = null) {
    const params = {
      parentId: parentId,
      userId: userId,
      typeId: typeId,
    };
    return Client.post(resource, params, {
      headers: {
        action: "member-suggest-username",
      },
    });
  },
  checkUsername(username, id = null) {
    const params = {
      username: username,
      user_id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "member-check-username",
      },
    });
  },
  options(payload) {
    payload = {
      ...payload,
      typeId: typeId,
    };
    return Client.post(resource, payload, {
      headers: {
        action: "list-option",
      },
    });
  },
};
