import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/api/admin/bet`;

export default {
  index(payload) {
    return Client.post(`${resource}`, payload, {
      headers: {
        action: "list-bet-number-v2",
      },
    });
  },
};
