import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/api/admin/lottery/result`;

export default {
  index(payload) {
    payload.startDate = payload.startDate ? `${payload.startDate}` : '';
    payload.endDate = payload.endDate ? `${payload.endDate}` : '';
    let params = prepareSearchParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "list-result",
      },
    });
  },
  total(payload) {
    payload.startDate = payload.startDate ? `${payload.startDate}` : '';
    payload.endDate = payload.endDate ? `${payload.endDate}` : '';
    let params = prepareSearchParams(payload);
    return Client.post(resource, params, {
      headers: {
        action: "list-result-total",
      },
    });
  },
  showCurrent(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "show-result",
      },
    });
  },
  publishResult(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "publish-result",
      },
    });
  },
  cancelResult(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "cancel-publish-result",
      },
    });
  },
  save(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "submit-result",
      },
    });
  },
};
