export default [
  {
    path: '/allow-number',
    name: 'list-allow-number',
    component: () => import('@/views/betSetting/allowNumber/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.allowNumber',
      navActiveLink: 'bet-setting',
      navActiveTab: 'allow-number',
      resource: 'bet-setting',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listAllowNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/allow-number/create',
    name: 'create-allow-number',
    component: () => import('@/views/betSetting/allowNumber/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.allowNumber',
      navActiveLink: 'bet-setting',
      navActiveTab: 'allow-number',
      resource: 'bet-setting',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listAllowNumber',
          to: { name: 'list-allow-number' }
        },
        {
          text: 'breadcrumb.createAllowNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/allow-number/:id/update',
    name: 'update-allow-number',
    component: () => import('@/views/betSetting/allowNumber/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.allowNumber',
      navActiveLink: 'bet-setting',
      navActiveTab: 'allow-number',
      resource: 'bet-setting',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listAllowNumber',
          to: { name: 'list-allow-number' }
        },
        {
          text: 'breadcrumb.updateAllowNumber',
          active: true,
        },
      ],
    },
  },
]
