import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { preparePaginationParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/api/admin/fight`;

export default {
  current(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "current-fight-live",
      }
    });
  },

  setup(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "setup-fight-live",
      }
    });
  },

  changeLiveStatus(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "update-fight-live-status",
      }
    });
  },

  updateFight(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "store-match",
      }
    });
  },

  updateBettingStatus(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "update-match-status",
      }
    });
  },

  updateFightResult(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "update-match-result",
      }
    });
  },

  nextMatch(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "next-match",
      }
    });
  },

  getDefaultFight(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "get-match-default",
      }
    });
  },

  updateDefaultFight(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "store-match-default",
      }
    });
  },

  listFightResult(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "list-match-result",
      }
    });
  },

  reUpdateFightResult(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "update-pre-match-result",
      }
    });
  },


  listFight(payload) {
    let params = preparePaginationParams(payload);
    if (payload.startDate) {
      params.filters.push({
        searchby: "created_at",
        searchoperator: ">",
        search: `${payload.startDate} 00:00:00`,
      });
    }
    if (payload.endDate) {
      params.filters.push({
        searchby: "created_at",
        searchoperator: "<",
        search: `${payload.endDate} 23:59:59`,
      });
    }
    if (payload.channelId) {
      params.filters.push({
        searchby: "channel_id",
        searchoperator: "=",
        search: payload.channelId,
      });
    }

    return Client.post(resource, params, {
      headers: {
        action: "list-match",
      }
    });
  },

  listFightTotal(payload) {
    let params = preparePaginationParams(payload);
    if (payload.startDate) {
      params.filters.push({
        searchby: "created_at",
        searchoperator: ">",
        search: `${payload.startDate} 00:00:00`,
      });
    }
    if (payload.endDate) {
      params.filters.push({
        searchby: "created_at",
        searchoperator: "<",
        search: `${payload.endDate} 23:59:59`,
      });
    }
    if (payload.channelId) {
      params.filters.push({
        searchby: "channel_id",
        searchoperator: "=",
        search: payload.channelId,
      });
    }

    return Client.post(resource, params, {
      headers: {
        action: "list-match-total",
      }
    });
  },
};
