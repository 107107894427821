export default [
  {
    path: '/block-number',
    name: 'list-block-number',
    component: () => import('@/views/betSetting/blockNumber/List.vue'),
    meta: {
      pageTitle: 'breadcrumb.blockNumber',
      navActiveLink: 'bet-setting',
      navActiveTab: 'block-number',
      resource: 'bet-setting',
      action: 'read',
      breadcrumb: [
        {
          text: 'breadcrumb.listBlockNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/block-number/create',
    name: 'create-block-number',
    component: () => import('@/views/betSetting/blockNumber/Create.vue'),
    meta: {
      pageTitle: 'breadcrumb.blockNumber',
      navActiveLink: 'bet-setting',
      navActiveTab: 'block-number',
      resource: 'bet-setting',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listBlockNumber',
          to: { name: 'list-block-number' }
        },
        {
          text: 'breadcrumb.createBlockNumber',
          active: true,
        },
      ],
    },
  },
  {
    path: '/block-number/:id/update',
    name: 'update-block-number',
    component: () => import('@/views/betSetting/blockNumber/Update.vue'),
    meta: {
      pageTitle: 'breadcrumb.blockNumber',
      navActiveLink: 'bet-setting',
      navActiveTab: 'block-number',
      resource: 'bet-setting',
      action: 'update',
      breadcrumb: [
        {
          text: 'breadcrumb.listBlockNumber',
          to: { name: 'list-block-number' }
        },
        {
          text: 'breadcrumb.updateBlockNumber',
          active: true,
        },
      ],
    },
  },
]
