import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/api/admin/setting/sellout`;

export default {
  index(payload) {
    let params = prepareSearchParams(payload);
    return Client.post(resource, {
      ...params,
      userId: payload.userId,
    }, {
      headers: {
        action: "list",
      },
    });
  },
  show(id) {
    return Client.post(resource, {
      id: id,
    }, {
      headers: {
        action: "get",
      },
    });
  },
  create(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "store",
      },
    });
  },
  update(id, payload) {
    return Client.post(resource, {
      id: id,
      ...payload,
    }, {
      headers: {
        action: "update",
      },
    });
  },
  destroy(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "delete",
      },
    });
  },
};
