import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/api/admin/lottery`;

export default {
  index() {
    return Client.post(resource, {
    }, {
      headers: {
        action: "list-lottery",
      },
    });
  },
  listActive() {
    return Client.post(resource, {
    }, {
      headers: {
        action: "active-lottery",
      },
    });
  },
  show(id) {
    return Client.post(resource, {
      lotteryId: id,
    }, {
      headers: {
        action: "get-lottery",
      },
    });
  },
  createTime(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "add-lottery-time",
      },
    });
  },
  updateTime(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "update-lottery-time",
      },
    });
  },
  options(payload) {
    payload = {
      ...payload,
    };
    return Client.post(resource, payload, {
      headers: {
        action: "list-lottery",
      },
    });
  },
  getClosePost(payload) {
    return Client.post(resource, payload, {
      headers: {
        action: "list-lottery-post-close",
      },
    });
  },
};
